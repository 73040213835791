;(function() {
    'use strict';


    // iPad and iPod detection
    // let isiPad = function() {
    //     return (navigator.platform.indexOf('iPad') != -1);
    // };
    //
    // let isiPhone = function() {
    //     return (
    //         (navigator.platform.indexOf('iPhone') != -1) ||
    //         (navigator.platform.indexOf('iPod') != -1)
    //     );
    // };

    // $(document).ready(function() {
    //     $('.animated').hide();
    // })


    // let particles
    // if (typeof window !== 'undefined') {
    //     require('./jquery.stellar.js')
    // }

    // Parallax
    // let parallax = function() {
    //     $(window).stellar();
    // };


    // Burger Menu
    let burgerMenu = function() {
        $('body').on('click', '.js-fh5co-nav-toggle', function(event) {
            event.preventDefault();

            if ($('#navbar').is(':visible')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
        });
    };


    let goToTop = function() {
        $('.js-gotop').on('click', function(event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $('html').offset().top
            }, 500);

            return false;
        });
    };


    // Page Nav
    let clickMenu = function() {
        $('#navbar a:not([class="external"])').click(function(event) {
            let section = $(this).data('nav-section'),
                navbar = $('#navbar');

            if ($('[data-section="' + section + '"]').length) {
                $('html, body').animate({
                    scrollTop: $('[data-section="' + section + '"]')
                        .offset().top
                }, 500);
            }

            if (navbar.is(':visible')) {
                navbar.removeClass('in');
                navbar.attr('aria-expanded', 'false');
                $('.js-fh5co-nav-toggle').removeClass('active');
            }

            event.preventDefault();
            return false;
        });
    };

    // Reflect scrolling in navigation
    let navActive = function(section) {
        let $el = $('#navbar > ul');
        $el.find('li').removeClass('active');
        $el.each(function() {
            $(this).find('a[data-nav-section="' + section + '"]')
                .closest('li').addClass('active');
        });
    };

    let navigationSection = function() {
        let $section = $('section[data-section]');

        $section.waypoint(function(direction) {
            if (direction === 'down') {
                navActive($(this.element).data('section'));
            }
        }, {
            offset: '150px'
        });

        $section.waypoint(function(direction) {
            if (direction === 'up') {
                navActive($(this.element).data('section'));
            }
        }, {
            offset: function() {
                return -$(this.element).height() + 155;
            }
        });
    };


    // Window Scroll
    let windowScroll = function() {
        // let lastScrollTop = 0;

        $(window).scroll(function(event) {
            let header = $('#fh5co-header'),
                scrlTop = $(this).scrollTop();

            if (scrlTop > 500 && scrlTop <= 2000) {
                header.addClass('navbar-fixed-top fh5co-animated slideInDown');
            } else if (scrlTop <= 500) {
                if (header.hasClass('navbar-fixed-top')) {
                    header
                        .addClass('navbar-fixed-top fh5co-animated slideOutUp');
                    setTimeout(function() {
                        header.removeClass('navbar-fixed-top ' +
                            'fh5co-animated slideInDown slideOutUp');
                    }, 100);
                }
            }
        });
    };

    let countersAnimate = function() {
        let counters = $('#fh5co-counters');
        if (counters.length > 0) {
            counters.waypoint(function(direction) {
                if (direction === 'down' && !$(this.element)
                    .hasClass('animated')) {
                    setTimeout(function() {
                        counters.find('.js-counter').countTo({
                            formatter: function(value, options) {
                                return value.toFixed(options.decimals);
                            },
                        });
                    }, 400);

                    $(this.element).addClass('animated');
                }
            }, {offset: '80%'});
        }
    };


    // Document on load.
    $(function() {
        burgerMenu();

        clickMenu();

        windowScroll();

        navigationSection();

        goToTop();

        countersAnimate();

    });
}());
