// console.log("contact form load");

$('#contact-form').submit(function(ev) {
    // Prevent the form from actually submitting
    ev.preventDefault();

    // Send it to the server
    $.post({
        url: '/',
        dataType: 'json',
        data: $(this).serialize(),
        success: function(response) {
            if (response.success) {
                $('#thanks').fadeIn();
                $(this).trigger("reset");
                $('#error-message').html('');
                $('#form-error').hide();
            } else {
                // response.error will be an object containing any validation errors that occurred, indexed by field name
                // e.g. response.error.fromName => ['From Name is required']
                // alert('Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es mit anderen Angeben erneut.');
                let errorStr = '';
                Object.keys(response.errors).forEach(function (prop) {
                    let value = response.errors[prop];
                    errorStr += value + '<br>'
                });
                // console.log(errorStr);
                $('#error-message').html(errorStr)
                $('#form-error').fadeIn();
            }
        }
    });
});
