'use strict';
// import 'jquery'
// import 'bootstrap'

import './js/bootstrap.min'

import './scss/index.scss'

import './js/jquery.easing.1.3'
import './js/jquery.waypoints.min'
import './js/jquery.countTo'

import './js/jquery.magnific-popup.min'
import './js/magnific-popup-options'

import ScrollReveal from './js/scrollreveal.min'

import './js/contact-form'
import './js/main'

// Documentation -> https://github.com/jlmakes/scrollreveal
window.sr = ScrollReveal();
sr.reveal('.to-animate');

$('.carousel').carousel();

$(document).ready(function() {
    console.log($().jquery);
    setTimeout(function () {
        $('.image-popup').removeAttr('style').css("opacity","1");
    },1000)
});


